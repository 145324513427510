import React from "react";
import "./App.scss";
import Header from "./components/Header/Header";
import Snippet from "./components/Snippet/Snippet";
import Column from "./components/Column/Column";
import CustomTerminal from "./components/Terminal/Terminal";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <section className="no-padding">
        <div className="d-flex header">
          <Header />
        </div>
      </section>
      <section className="overlay-bg">
        <div className="overlay-bg-inner">
          <h1 className="primary-text">Verify the email instantly</h1>
          <p className="secondary-text">
            Identify the spam email while authenticating your users
          </p>
        </div>
        <span className="position-absolute">
          <div className="overflow-hidden">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </span>
      </section>
      <CustomTerminal />
      <section className="unmatched-performance">
        <div className="unmatched-performance-inner">
          <Column />
        </div>
      </section>
      <section className="language">
        <Snippet />
      </section>
      <Footer />
    </div>
  );
}

export default App;
