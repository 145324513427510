import React from "react";
import uptime from "../../assets/24-7.svg";
import request_million from "../../assets/million.svg";
import response_time from "../../assets/load.svg";
import styles from "./Column.module.scss";

export default function SimpleCard() {
  return (
    <div className="secondContainer">
      <h2 className="primary-text">Unmatched Performance</h2>
      <p className="secondary-text">
        You will never need to worry about the infrastructure
      </p>
      <div className={styles.flexContainer}>
        <div className={styles.flexBox}>
          <img
            className={styles.icons}
            src={request_million}
            alt="5 Miliion request per day"
          />
          <h3>5 Miliion</h3>
          <h4 className="secondary-text">requests per day</h4>
        </div>
        <div className={styles.flexBox}>
          <img
            className={styles.icons}
            src={response_time}
            alt="Response time less than 500 ms"
          />
          <h3>Response time </h3>
          <h4 className="secondary-text">{`< 500ms`}</h4>
        </div>
        <div className={styles.flexBox}>
          <img className={styles.icons} src={uptime} alt="99.99 % uptime" />
          <h3>99.99%</h3>
          <h4 className="secondary-text">Uptime</h4>
        </div>
      </div>
    </div>
  );
}
