import React from "react";
import styles from "./Terminal.module.scss";
import dots from "../../assets/dots.svg";
const URL = `https://api.eva.pingutil.com/email?email=`;
const EMAIL_VALUE = "test@mail7.io";
let xhrResponseText = "";
const SPINNER_SPEED = 200;
const TEXT_SPEED = 50;
const SPINNER_FRAME = ["←", "↖", "↑", "↗", "→", "↘", "↓", "↙"];
const INITIAL_COMMAND = `curl "${URL}${EMAIL_VALUE}" | json_pp`;
let RESPONSE_SPEED = 50;
class CustomTerminal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      command: "",
      response: "",
      spinner: "",
      loading: true,
      emailValue: EMAIL_VALUE,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  animateCommand = (command) => {
    let counter = 0;
    let animate = () => {
      if (counter < command.length) {
        this.setState({
          command: `${this.state.command}${command.charAt(counter)}`,
        });
        counter++;
        setTimeout(animate, TEXT_SPEED);
      } else {
        this.updateResponse();
      }
    };
    animate();
  };

  updateContent = () => {
    this.animateCommand(INITIAL_COMMAND);
  };

  showspinner = () => {
    let counter = 0;
    let animate = () => {
      if (counter < SPINNER_FRAME.length) {
        const frameValue = SPINNER_FRAME[counter];
        this.setState({ spinner: `${frameValue}` });
        counter++;
        setTimeout(animate, SPINNER_SPEED);
      } else {
        counter = 0;
      }
    };
    animate();
  };

  updateOutput = () => {
    let counter = 0;
    let animate = () => {
      if (counter < xhrResponseText.length) {
        this.setState({
          response: `${this.state.response}${xhrResponseText.charAt(counter)}`,
        });
        setTimeout(animate, RESPONSE_SPEED);
        counter++;
      } else {
        this.setState({
          loading: false,
          emailValue: "",
        });
      }
    };
    animate();
  };

  updateResponse = () => {
    this.showspinner();
    var xhr = new XMLHttpRequest();
    xhr.open("GET", URL + this.state.emailValue, true);
    xhr.onload = () => {
      let response = JSON.parse(xhr.responseText);
      if (xhr.readyState === 4 && xhr.status === 200) {
        xhrResponseText = JSON.stringify(response, null, 4);
        this.updateOutput();
      } else {
        console.error(response);
      }
    };
    xhr.send(null);
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        command: "",
        response: "",
        spinner: "",
        loading: true,
      },
      () => {
        const updatedCommand = `curl "${URL}${this.state.emailValue}" | json_pp`;
        RESPONSE_SPEED = 1;
        this.animateCommand(updatedCommand);
      }
    );
  }

  handleChange(event) {
    this.setState({ emailValue: event.target.value });
  }

  componentDidMount() {
    this.updateContent();
  }

  render() {
    return (
      <div className={styles.parent}>
        <div className={styles.wrapper}>
          <div className={styles.termHeader}>
            <img src={dots} alt="dots" />
          </div>
          <div className={styles.terminalBlock}>
            <pre>
              <span className={styles.termBox}>$</span>
              {this.state.command}
              <br />
              {this.state.spinner && (
                <span>
                  <span className={styles.termBox}>$</span>
                  {this.state.spinner}
                </span>
              )}
              <br />
              {this.state.response && (
                <span>
                  <span className={styles.termBox}>$</span>
                  {this.state.response}
                </span>
              )}
            </pre>
          </div>
          {/* <div className={styles.emailBox}>
            <div>
              <span className={styles.termBox}>$</span>
              {this.state.loading ? (
                <span>
                  <pre className={styles.inputStyle}>{this.state.command}</pre>
                </span>
              ) : (
                  null
                )}
            </div>
          </div> */}
        </div>
        <div className={styles.formContainer}>
          <form className={styles.formStyle} onSubmit={this.handleSubmit}>
            <input
              className={styles.emailInput}
              onChange={this.handleChange}
              type="email"
              value={this.state.emailValue}
              placeholder="type any email and hit enter"
            />
          </form>
        </div>
      </div>
    );
  }
}

export default CustomTerminal;
