/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./Header.module.scss";
import Link from "@material-ui/core/Link";
// import logo from "../../assets/logo.svg";
export default function HeaderComponent() {
  const preventDefault = (event) => event.preventDefault();

  return (
    <div className={styles.nav}>
      <Link style={{ textDecoration: 'none' }} href="#" onClick={preventDefault}>
        <span className={styles.Logotext}>EVA <sup className={styles.Beta}>Beta</sup></span>
      </Link>
    </div>
  );
}
