import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// import SyntaxHighlighter from 'react-syntax-highlighter';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { prism } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
// import prism from 'react-syntax-highlighter/dist/esm/styles/prism/prism';
import styles from "./Snippet.module.scss";

const BASE_URL_HTTPS = "https://api.eva.pingutil.com";
const BASE_URL = "api.eva.pingutil.com";

const TEST_EMAIL = "test@mail7.io" 
const TEST_EMAIL_ENCODED = encodeURIComponent(TEST_EMAIL);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={6} xs={6}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Snippet() {
  const [value, setValue] = React.useState(0);
  const languages = [
    "cURL",
    "HTTP",
    "JavaScript(Fetch)",
    "JavaScript(jQuery)",
    "JavaScript(XHR)",
    "Go",
    "Node.JS",
    "Python",
    "Java(OkHttp)",
    "PHP"
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={styles.container}>
      <h2 className="primary-text mb-60">Use in your own language</h2>
      <div className={styles.box}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={styles.tabs}
        >
          {languages.map((data) => {
            return <Tab label={data} key={data} />;
          })}
        </Tabs>
        <div className={styles.tabContainer}>
          <div>
          </div>
          <div style={{ width: "100%" }}>
            <TabPanel value={value} index={0} className={styles.tabpanel}>
              <SyntaxHighlighter language="javascript" style={dark}>
                {`curl --location --request GET '${BASE_URL_HTTPS}/email?email=${TEST_EMAIL}`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={1} className={styles.tabpanel}>
              <SyntaxHighlighter language="shell" style={dark}>
                {`GET /email?email=${TEST_EMAIL} HTTP/1.1
Host: ${BASE_URL_HTTPS}`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={2} className={styles.tabpanel}>
              <SyntaxHighlighter language="javascript" style={dark}>
                {`var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  fetch("${BASE_URL_HTTPS}/email?email=${TEST_EMAIL}", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
    `}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={3} className={styles.tabpanel}>
              <SyntaxHighlighter language="javascript" style={dark}>
                {`var settings = {
  "url": "${BASE_URL_HTTPS}/email?email=${TEST_EMAIL}",
  "method": "GET",
  "timeout": 0,
};
$.ajax(settings).done(function (response) {
  console.log(response);
});`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={4} className={styles.tabpanel}>
              <SyntaxHighlighter
                language="javascript"
                style={dark}
                className={styles.tabpanel}>
                {`var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
	if (this.readyState === 4) {
		console.log(this.responseText);
	}
});

xhr.open("GET", "${BASE_URL_HTTPS}/email?email=${TEST_EMAIL}");

xhr.send();`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={5} className={styles.tabpanel}>
              <SyntaxHighlighter
                language="go"
                style={dark}
                className={styles.tabpanel}>
                {`package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "${BASE_URL_HTTPS}/email?email=${TEST_EMAIL}"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
  }
  res, err := client.Do(req)
  defer res.Body.Close()
  body, err := ioutil.ReadAll(res.Body)

  fmt.Println(string(body))
}`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={6} className={styles.tabpanel}>
              <SyntaxHighlighter
                language="javascript"
                style={dark}
                className={styles.tabpanel}>
{`var https = require('follow-redirects').https;
var fs = require('fs');

var options = {
  'method': 'GET',
  'hostname': '${BASE_URL_HTTPS}',
  'path': '/email?email=${TEST_EMAIL_ENCODED}',
  'headers': {
  },
  'maxRedirects': 20
};

var req = https.request(options, function (res) {
  var chunks = [];

  res.on("data", function (chunk) {
    chunks.push(chunk);
  });

  res.on("end", function (chunk) {
    var body = Buffer.concat(chunks);
    console.log(body.toString());
  });

  res.on("error", function (error) {
    console.error(error);
  });
});

req.end();`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={7} className={styles.tabpanel}>
              <SyntaxHighlighter
                language="python"
                style={dark}
                className={styles.tabpanel}>
                {`import http.client
import mimetypes
conn = http.client.HTTPSConnection("${BASE_URL}")
payload = ''
headers = {}
conn.request("GET", "/email?email=${TEST_EMAIL}", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={8} className={styles.tabpanel}>
              <SyntaxHighlighter
                language="javascript"
                style={dark}
                className={styles.tabpanel}>
                {`OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("${BASE_URL_HTTPS}/email?email=${TEST_EMAIL}")
  .method("GET", null)
  .build();
Response response = client.newCall(request).execute();`}
              </SyntaxHighlighter>
            </TabPanel>
            <TabPanel value={value} index={9} className={styles.tabpanel}>
              <SyntaxHighlighter
                language="php"
                style={dark}
                className={styles.tabpanel}>
{`<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "${BASE_URL_HTTPS}/email?email=${TEST_EMAIL}",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "GET",
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;
`}
</SyntaxHighlighter>
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
