/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./Footer.module.scss";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className="left powered-by">
        Sponsored by &nbsp;
          <a href="https://www.compile7.com" target="_blank" rel="noopener noreferrer">
          Compile7
          </a>
      </div>
      <div className="right powered-by">
        Share your &nbsp;
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfa76A9ES0wvIF0_I7ebLAFugVhhDKB7em-WPbMjHPj1w7mOw/viewform" target="_blank" rel="noopener noreferrer">
          Feedback
          </a>
      </div>
    </div>
  );
}
